.text-hover:hover{
    color: #2d4059 !important;
    cursor: default;
}

.university-position {
    color: #A7E521 !important;
}

.general-university-information > div {
    margin-bottom: 2%;
}

.statistics-information > div {
    margin-bottom: 6%;
}

.primary-information {
    color: #aab7c4 !important;
    font-size: 0.75rem;
}

.title {
    color: #aab7c4;
    font-size: 13px;
}

.sub-title {
    font-size: 16px;
}

.statistics-information {
    /*border: solid;*/
}

.add-icon-university {
    border: solid;
    border-radius: 10px;
    height: 17px;
    width: 17px;
    background-color: white;
}

.circle {
    width: 12px;
    height: 12px;
    border-radius: 6px;
    transition: all .3s ease;
}

.custom-scroll {
    overflow: auto !important;
    max-height: 200px !important;
  }
